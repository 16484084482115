import './App.css';
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import Login from "./Components/recruiter/Login";
import AuthUser from './Components/AuthUser';
import Header from './Components/Header';
import ForgotPassword from './Components/ForgotPassword';
import ResetPassword from './Components/ResetPassword';
import CreatePassword from './Components/CreatePassword';


const App = () => {
  const {getUser} = AuthUser();
  
  if(!getUser()){
    return (
      <div className="App">
      <Routes>
        <Route path="/" exact element={<Login /> } />
        <Route path="/ForgotPassword" exact element={<ForgotPassword /> }/>
        <Route path="/ResetPassword/:id/:id1" exact element={<ResetPassword /> }/>
        <Route path="/CreatePassword/:id/:id1" exact element={<CreatePassword /> }/>
      </Routes> 
    </div>
    );
  }
  return (
      <>          
        <Header />
      </>
  );
}
export default App;