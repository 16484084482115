import React,{ useEffect, useState } from 'react'
import { CardLink } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import comment from '../Assets/comment.png';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import AuthUser from './AuthUser';
import axios from 'axios';

function Comments(props) {
    const{getUser,http} = AuthUser();
    let userdetails = getUser();
    const{data}=props;
    console.log(data.id)
    const [smShow, setSmShow] = useState(false);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}`;
    };

    const [formData, setFormData] = useState({ comment: '' });
    const [comments, setComments] = useState([]);
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value});
    };
    const handleCancel = () => {
        setFormData({ comment: '' });
    };
   
    function fetchData(){
        http.get(`/recruiter/rccomment/${userdetails.email}/${data.id}`).then((response) => {
            setComments(response.data.comments);
        }).catch()
    }
    useEffect(() => {
        fetchData();
    }, []);
    const refreshData = () =>{
        fetchData();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        http.post(`/recruiter/rctalentcomment/${userdetails.email}/${data.id}`, formData)
        .then((response) =>{
            setSmShow(true); 
            setFormData({ comment: '' });
            refreshData();
          }
        ).catch (function (error) {
            if (error.response && error.response.status === 403) {
                setErrors(error.response.data.error);
            }
        });
    };

    return (
        <div>
            <CardLink href="#" onClick={() => setSmShow(true)}> <img src={comment} alt='Profile' className='img-fluid' style={{ width: 30 }} /></CardLink>
            <Modal size="md" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="example-modal-sizes-title-sm" >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <span className='fw-bold'>Comments</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={handleSubmit} >
                    
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" name="comment" rows={5} placeholder='Enter a comment' className='rounded-0' style={{height:'100px'}} value={formData.comment} onChange={handleChange}/>
                        <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.comment && errors.comment[0]}</Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="secondary" onClick={handleCancel}  className="border-0 text-dark" style={{ backgroundColor: '#FFFFFF' }}> Cancel</Button>
                    <Button variant="primary" type="submit" style={{ backgroundColor: '#2C99C9' }}> Submit</Button>
                    </form>
                    

                    {comments.map(comment => (
                    <Row className="mt-4 border-bottom">
                        <div class="d-flex align-items-center">
                            <div class="flex-grow-1 ms-3">
                                <span className="fw-bold" style={{ color: '#2589B5' }}>{userdetails.firstname}</span> | <span style={{ color: '#9A9A9A' }}>{formatDate(comment.created_at)}</span> 
                            </div>
                        </div>
                        <p className="pt-3">{comment.comment}</p>
                    </Row>
                    ))}
                </Modal.Body>
            </Modal>
        </div>
    )

}

export default Comments