import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AuthUser(){
    const navigate = useNavigate();
    //const apiurl="http://localhost:8000/";
    const apiurl="https://api.employmentexchange.com/";
    const getUser = () =>{
        const userString = sessionStorage.getItem('user');
        const user_detail = JSON.parse(userString);
        return user_detail;
    }

    const [user,setUser] = useState(getUser());

    const saveToken = (user) =>{
        sessionStorage.setItem('user',JSON.stringify(user));
        setUser(user);
        navigate(`/Dashboard`);
    }
    const saveUser = (user) =>{
        sessionStorage.setItem('user',JSON.stringify(user));
        setUser(user);
    }

    const logout = () => {
        sessionStorage.clear();
        navigate('/');
    }

    const http = axios.create({
        baseURL:`${apiurl}api`,
        headers:{
            "Access-Control-Allow-Origin": "*",
            "Content-type" : "application/json",
        }
    });

    return {
        setToken:saveToken,
        user,
        setUser:saveUser,
        getUser,
        http,
        logout,
        apiurl
    }
}