import React from 'react'
import Modal from 'react-bootstrap/Modal';
import AuthUser from './AuthUser';
function Viewdocument(props) {
    const { apiurl } = AuthUser();
    var filesrc=String(props.filename).split('.').pop() == 'pdf' ? 
                `${apiurl}uploads/${props.foldertype}/${props.filename}`                    
                : 
                `https://view.officeapps.live.com/op/embed.aspx?src=${apiurl}uploads/${props.foldertype}/${props.filename}`;
      
  return (
  <>
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={props.show} onHide={props.close} centered>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">View Document</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
              
            {/*             
            <iframe src="https://view.officeapps.live.com/op/embed.aspx?src=https://h1bapplicants.com/public/uploads/admin_documents/1641807893.docx" height="680"  width="100%"  frameborder="0"></iframe>
            */ }
            <iframe src={filesrc}  height="780"  width="100%"></iframe>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
    </Modal>
  </>
  )
}

export default Viewdocument