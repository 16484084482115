import React ,{ useState, useEffect, useMemo }from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { CardLink } from 'react-bootstrap';
import Innerfooter from '../Innerfooter';
import ListGroup from 'react-bootstrap/ListGroup';
import 'react-tagsinput/react-tagsinput.css';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import axios from 'axios';
import AuthUser from '../AuthUser';
import moment from 'moment';

function Browsinghistory() {
    const{http,getUser} = AuthUser();
    let userdetails = getUser();
    const [rowData, setRowData] = useState([]);

    function fetchData(){
        http.get(`/recruiter/browsinghistory/${userdetails.email}`).then((response) => {
            setRowData(response.data.browsinghistory);
        }).catch()
    }
    useEffect(() => {
        fetchData();
    }, []);

    const [colDefs, setColDefs] = useState([
        { headerName: 'Action Performed', field: 'task' },
        { headerName: 'IP Address', field: 'ipaddress' },
        { headerName: 'Date / Time', field: 'created_at',
            valueFormatter: (params) => {
                return moment(params.value).format('DD-MMM-YYYY');
            }
         },
    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,
        }
    });

    
  
    return (
        <Container fluid>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>Browsing History </h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} >
                </Col>
                <div className="ag-theme-quartz"  style={{ height: 550 }} >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                    />
                </div>
            </Row>
        <Innerfooter/>
        </Container>
   );
}
export default Browsinghistory