import React, { useEffect, useState, useMemo } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Talentmanagementmenu from '../Talentmangementmenu';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Innerfooter from '../Innerfooter';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import AuthUser from '../AuthUser';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const Searchresults = () => {
    const{http,getUser} = AuthUser();
    let userdetails = getUser();
    const [rowData, setRowData] = useState([]);
    const query = new URLSearchParams(useLocation().search).get('query');

    const colDefs = [
        { headerName: 'First Name', field: 'firstname' },
        { headerName: 'Last Name', field: 'lastname' },
        { headerName: 'Job Title', field: 'jobtitle' },
        { headerName: 'Experience', field: 'experience' },
        { headerName: 'Current Location', field: 'location' },
        { headerName: 'Relocation', field: 'relocation' },
        { headerName: 'Work Authorization', field: 'workauthorization' },
        { headerName: 'Submitted Date', field: 'created_at' },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,
        }
    });

    useEffect(() => {
        if (query) {
            /*
            fetch(`http://localhost:8000/api/recruiter/search/${userdetails.email}?query=${query}`)
                .then((response) => response.json())
                .then((data) => setRowData(data));
                */
            http.get(`/recruiter/search/${userdetails.email}?query=${query}`).then((response) =>{
                setRowData(response.data.talents)
               }).catch(function (error) {
                // handle error
                console.log(error.response.data.error);
            });
        }
    }, [query]);

    return (
        <Container fluid>
            <Talentmanagementmenu />
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>Search Results </h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} >
                </Col>
                <div className="ag-theme-quartz"  style={{ height: 550 }} >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                    />
                </div>
            </Row>
            <Innerfooter/>
        </Container>
    );
}

export default Searchresults;