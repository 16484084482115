import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Talentmanagementmenu from '../Talentmangementmenu';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Innerfooter from '../Innerfooter';
import InputGroup from 'react-bootstrap/InputGroup';
import AuthUser from '../AuthUser';
import axios from 'axios';
import InputMask from 'react-input-mask';

function TalentListing() {
  const{getUser,http} = AuthUser();
  let userdetails = getUser();
  const [valid,setValid]=useState('')
  const [states, setStates] = useState([]);
  const [workauths, setWorkauths] = useState([]);
  const [count, setCount] = useState(1);
  const [countmonth, setCountmonth] = useState(1);
  const incrementYear = () => {
    setCount(prevCount => prevCount + 1);
  };
  const decrementYear = () => {
    setCount(prevCount => prevCount - 1);
  };

  const incrementValue = () => {
    setCountmonth(prevValue => prevValue + 1);
  };
  const decrementValue = () => {
    setCountmonth(prevValue => prevValue - 1);
  };

  function fetchData(){
      http.get(`/recruiter/states/${userdetails.email}`).then((response) => {
        setStates(response.data.states);
      }).catch()
  }

  function fetchData1(){
      http.get(`/recruiter/workauths/${userdetails.email}`).then((response) => {
        setWorkauths(response.data.workauths);
      }).catch()
  }

  useEffect(() => {
      fetchData();
      fetchData1();
  }, []);
  
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    jobtitle: '',
    experience: '',
    months: '',
    rate: '',
    taxterm: '',
    payfrequency: '',
    employername: '',
    contactname: '',
    phone: '',
    empemail: '',
    designation: '',
    weburl: '',
    location: '',
    relocation: '',
    workauthorization: '',
    resume: '',
    id: userdetails.id,
  });

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState('');
  const handleChange = (e) => {
    if (e.target.name == "resume") {
        setFormData({ ...formData, resume: e.target.files[0] });
    } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if(e.target.name == 'taxterm'){
      //console.log(e.target.value)
      setValid(e.target.value)
    }
  };

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{3}-\d{3}-\d{4}$/;
    const allowedFileTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
      //"text/plain",
    ];
    const maxFileSize = 5 * 1024 * 1024; 
       
    if (!formData.firstname.trim()) newErrors.firstname = 'First Name is required.';
    if (!formData.lastname.trim()) newErrors.lastname = 'Last Name is required.';
    if (!formData.email || !emailRegex.test(formData.email)) newErrors.email = 'Invalid email address.';
    if (!formData.mobile || !mobileRegex.test(formData.mobile)) newErrors.mobile = 'Mobile number must be in the format 999-999-9999.';
    if (!formData.jobtitle.trim()) newErrors.jobtitle = 'Job Title is required.';
    if (!formData.rate.trim()) newErrors.rate = 'Rate is required.';
    if (!formData.payfrequency.trim()) newErrors.payfrequency = 'Pay frequency is required.';
    if (!formData.taxterm.trim()) newErrors.taxterm = 'Tax term is required.';
    if (formData.taxterm == 'corptocorp') {
      if (!formData.employername.trim()) newErrors.employername = 'Employer Name is required.';
      if (!formData.contactname.trim()) newErrors.contactname = 'Contact Name is required.';
      if (!formData.phone || !mobileRegex.test(formData.phone)) newErrors.phone = 'Phone number must be in the format 999-999-9999.';
      if (!formData.empemail || !emailRegex.test(formData.empemail)) newErrors.empemail = 'Invalid employer email address.';
      if (!formData.designation.trim()) newErrors.designation = 'Designation is required.';
      if (!formData.weburl.trim()) newErrors.weburl = 'URL is required.';
    }
    if (!formData.location.trim()) newErrors.location = 'Current location is required.';
    if (!formData.relocation.trim()) newErrors.relocation = 'Relocation is required.';
    if (!formData.workauthorization.trim()) newErrors.workauthorization = 'Work authorization is required.';
    if (!formData.resume) newErrors.resume = 'Resume is required.';
    else if (!allowedFileTypes.includes(formData.resume.type)) newErrors.resume = 'Invalid file type. Allowed types: .doc, .docx, .pdf..';
    else if (formData.resume.size > maxFileSize) newErrors.resume = 'File size exceeds 5 MB.';
        
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

    
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
   
    let firstname=e.target.firstname.value;
    let lastname=e.target.lastname.value;
    let email=e.target.email.value;
    let mobile=e.target.mobile.value;
    let jobtitle=e.target.jobtitle.value;
    let experience=e.target.experience.value;
    let months=e.target.months.value;
    let rate=e.target.rate.value;
    let payfrequency=e.target.payfrequency.value;
    let taxterm=e.target.taxterm.value;
    let location=e.target.location.value;
    let relocation=e.target.relocation.value;
    let linkedinid=e.target.linkedinid.value;
    let workauthorization=e.target.workauthorization.value;
    let resume = e.target.resume.files[0];
    let employername=(taxterm == 'corptocorp') ? e.target.employername.value : '';
    let contactname=(taxterm == 'corptocorp') ?  e.target.contactname.value : '';
    let phone=(taxterm == 'corptocorp') ?  e.target.phone.value : '';
    let empemail=(taxterm == 'corptocorp') ?  e.target.empemail.value : '';
    let designation=(taxterm == 'corptocorp') ?  e.target.designation.value : '';
    let weburl=(taxterm == 'corptocorp') ?  e.target.weburl.value : '';

    if (validate()) {
      http.post(`/recruiter/addtalent/${userdetails.email}`, {firstname:firstname,lastname:lastname,jobtitle:jobtitle,email:email,
        mobile:mobile,experience:experience,months:months,rate:rate,payfrequency:payfrequency,taxterm:taxterm,location:location,relocation:relocation,linkedinid:linkedinid,workauthorization:workauthorization,
        employername:employername,contactname:contactname,phone:phone,empemail:empemail,designation:designation,weburl:weburl,resume:resume},{
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) =>{
          setSuccess(response.data.message);
        }
      ).catch (function (error) {
          if (error.response && error.response.status === 403) {
              setErrors(error.response.data.error);
          }
      });
    }
    
  };
    
  return (
      <Container fluid>
          <Talentmanagementmenu />
          <Row className='mb-5 mt-5'>
              <Card style={{ width: '40rem' }} className='m-auto rounded-0'>
                  <Card.Body>
                      <Card.Title>Talent Listing</Card.Title>
                      <Card.Text>
                          <div class="mb-3">
                                { success !== '' && <div className="alert alert-success">{success}</div> }
                                <Form method="post" onSubmit={handleSubmit} noValidate>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label className='fw-bold'>First Name *</Form.Label>
                                      <Form.Control name='firstname' type="text" className="rounded-0 postjobinput " placeholder="Enter First Name" value={formData.firstname} onChange={handleChange}  isInvalid={!!errors.firstname} />
                                      <Form.Control.Feedback type="invalid">{errors.firstname }</Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label className='fw-bold'>Last Name *</Form.Label>
                                      <Form.Control name='lastname' type="text" className="rounded-0 postjobinput " placeholder="Enter Last Name" value={formData.lastname} onChange={handleChange} isInvalid={!!errors.lastname} />
                                      <Form.Control.Feedback type="invalid">{errors.lastname }</Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label className='fw-bold'>Email ID *</Form.Label>
                                      <Form.Control name='email' type="email" className="rounded-0 postjobinput " placeholder="Enter Your Email" value={formData.email} onChange={handleChange} isInvalid={!!errors.email} />
                                      <Form.Control.Feedback type="invalid">{errors.email }</Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label className='fw-bold'>Mobile Number *</Form.Label>
                                      <InputGroup className="mb-3">
                                      <InputGroup.Text id="basic-addon1">+1</InputGroup.Text>
                                      <InputMask mask="999-999-9999" className="rounded-0 postjobinput form-control" value={formData.mobile} onChange={handleChange} name="mobile" isInvalid={!!errors.mobile}>
                                        {(inputProps) => <input {...inputProps} type="text" />}
                                      </InputMask>
                                      <Form.Control.Feedback type="invalid" style={{ display:'block'}}>{errors.mobile}</Form.Control.Feedback>
                                  </InputGroup>
                                  </Form.Group>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label className='fw-bold'>Job Title *</Form.Label>
                                      <Form.Control name='jobtitle' type="text" className="rounded-0 postjobinput " placeholder="Enter Job Title" value={formData.jobtitle} onChange={handleChange} isInvalid={!!errors.jobtitle} />
                                      <Form.Control.Feedback type="invalid">{errors.jobtitle }</Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='fw-bold'>Experience *</Form.Label>
                                    <div class="number  d-flex justify-content-between">
                                      <span onClick={decrementYear}  className="minus">-</span>
                                      <input type="number" className="form-control" style={{ border:'1px'}} name="experience" value={count} />
                                      <span onClick={incrementYear } className="plus " >+</span>
                                      <span onClick={decrementValue} className="minus">-</span>
                                      <input type="number" className="form-control" style={{ border:'1px'}} name="months" value={countmonth} />
                                      <span onClick={incrementValue} className="plus " >+</span>
                                    </div>
                                  </Form.Group>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label className='fw-bold'>Rate *</Form.Label>
                                      <Form.Control name='rate' type="text" className="rounded-0 postjobinput " placeholder="Enter Rate" value={formData.rate} onChange={handleChange} isInvalid={!!errors.rate} />
                                      <Form.Control.Feedback type="invalid">{errors.rate }</Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1 ">
                                      <Form.Label className='fw-bold'>Pay Frequency *</Form.Label>
                                      <Form.Select name='payfrequency' aria-label="Default select example" className='rounded-0 postjobinput' value={formData.payfrequency} onChange={handleChange} isInvalid={!!errors.payfrequency}>
                                      <option value="">Select Pay Frequency</option>
                                      <option value="Yearly">Yearly</option>
                                      <option value="Monthly">Monthly</option>
                                      <option value="BiWeekly">BiWeekly</option>
                                      <option value="Weekly">Weekly</option>
                                      <option value="Daily">Daily</option>
                                      <option value="Hourly">Hourly</option>
                                      </Form.Select>
                                      <Form.Control.Feedback type="invalid">{errors.payfrequency }</Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1 ">
                                      <Form.Label className='fw-bold'>Tax Term *</Form.Label>
                                      <Form.Select name='taxterm' aria-label="Default select example" className='rounded-0 postjobinput' id="formType" value={formData.taxterm} onChange={handleChange} isInvalid={!!errors.taxterm}>
                                        <option value="">Select Tax Term</option>
                                        <option value="corptocorp">Corp to Corp </option>
                                        <option value="w2">W2</option>
                                        <option value="1099">1099</option>
                                      </Form.Select>
                                      <Form.Control.Feedback type="invalid">{errors.taxterm }</Form.Control.Feedback>
                                      {
                                        (valid == 'corptocorp') &&
                                                (
                                                  <div  style={{padding:"20px", border:'1px solid #000', display: 'block'}}>
                                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Control name='employername' type="text" className="rounded-0 postjobinput " placeholder="Enter Employer Name *" value={formData.employername} onChange={handleChange} isInvalid={!!errors.employername} />
                                                    <Form.Control.Feedback type="invalid">{errors.employername }</Form.Control.Feedback>
                                                  </Form.Group>
                                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Control name='contactname' type="text" className="rounded-0 postjobinput " placeholder="Enter Contact Name *" value={formData.contactname} onChange={handleChange} isInvalid={!!errors.contactname} />
                                                    <Form.Control.Feedback type="invalid">{errors.contactname }</Form.Control.Feedback>
                                                  </Form.Group>
                                                  <InputGroup className="mb-3">
                                                      <InputGroup.Text id="basic-addon1">+1</InputGroup.Text>
                                                      <InputMask mask="999-999-9999" className="rounded-0 postjobinput form-control" value={formData.phone} onChange={handleChange} name="phone">
                                                        {(inputProps) => <input {...inputProps} type="text" />}
                                                      </InputMask>
                                                      <Form.Control.Feedback type="invalid" style={{ display:'block'}}>{errors.phone }</Form.Control.Feedback>
                                                  </InputGroup>
                                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Control name='empemail' type="text" className="rounded-0 postjobinput " placeholder="Enter Email *" value={formData.empemail} onChange={handleChange} isInvalid={!!errors.empemail} />
                                                    <Form.Control.Feedback type="invalid">{errors.empemail }</Form.Control.Feedback>
                                                  </Form.Group>
                                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Control name='designation' type="text" className="rounded-0 postjobinput " placeholder="Enter Designation *" value={formData.designation} onChange={handleChange} isInvalid={!!errors.designation} />
                                                    <Form.Control.Feedback type="invalid">{errors.designation }</Form.Control.Feedback>
                                                  </Form.Group>
                                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Control name='weburl' type="text" className="rounded-0 postjobinput " placeholder="www.website.com *" value={formData.weburl} onChange={handleChange} isInvalid={!!errors.weburl} />
                                                    <Form.Control.Feedback type="invalid">{errors.weburl }</Form.Control.Feedback>
                                                  </Form.Group>
                                                </div>
                                                )}
                                      
                                  </Form.Group>
                                  <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1 ">
                                  <Form.Label className='fw-bold'>Current Location *</Form.Label>
                                    <Form.Select name='location' aria-label="Default select example" className='rounded-0 postjobinput' value={formData.location} onChange={handleChange} isInvalid={!!errors.location} >
                                    <option value="" selected="" disabled="">Select Current Location</option>
                                    {states.map(state => (
                                      <option value={state.statename}>{state.statename}</option>
                                    ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.location }</Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1 ">
                                    <Form.Label className='fw-bold'>Relocation *</Form.Label>
                                    <Form.Select name='relocation' aria-label="Default select example" className='rounded-0 postjobinput' value={formData.relocation} onChange={handleChange} isInvalid={!!errors.relocation}>
                                      <option value="">Select Relocation*</option>
                                      <option value="yes">Yes</option>
                                      <option value="no">No</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.relocation }</Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label className='fw-bold'>LinkedIn ID / Employmentexchange ID</Form.Label>
                                      <Form.Control name='linkedinid' type="text" className="rounded-0 postjobinput " placeholder="LinkedIn ID / Employmentexchange ID(Optional)" value={formData.linkedinid} onChange={handleChange} />
                                  </Form.Group>
                                  <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1 ">
                                    <Form.Label className='fw-bold'>Work Authorization *</Form.Label>
                                    <Form.Select name='workauthorization' aria-label="Default select example" className='rounded-0 postjobinput' value={formData.workauthorization} onChange={handleChange} isInvalid={!!errors.workauthorization}>
                                      <option value="" selected="" disabled="">Work Authorization</option>
                                      {workauths.map(workauth => (
                                        <option value={workauth.workauthorization}>{workauth.workauthorization}</option>
                                      ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.workauthorization }</Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label className='fw-bold'>Upload CV *</Form.Label>
                                    <Form.Control name='resume' type="file"  className='rounded-0 postjobinput'  onChange={handleChange} isInvalid={!!errors.resume} />
                                    <Form.Control.Feedback type="invalid">{errors.resume }</Form.Control.Feedback>
                                  </Form.Group>
                                  <div className='text-center'>
                                    <button type="submit" class="btn btn-primary rounded-pill" style={{ backgroundColor: '#2F9DCC' }}>Submit</button>
                                  </div>
                              </Form>
                          </div>
                      </Card.Text>
                      
                  </Card.Body>
              </Card>
          </Row>
          <Innerfooter/>
      </Container>
  );
}

export default TalentListing
