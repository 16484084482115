import React ,{ useState, useEffect, useMemo} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Talentmanagementmenu from '../Talentmangementmenu';
import Col from 'react-bootstrap/Col';
import Innerfooter from '../Innerfooter';
import 'react-tagsinput/react-tagsinput.css';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Pdf from "../Pdf.jsx";
import AuthUser from '../AuthUser';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

function Rcreports() {
    const{getUser} = AuthUser();
    let userdetails = getUser();
    const [rowData, setRowData] = useState([]);
    const folderName = 'rctalents';
    const [modalshow, setModalshow] = useState(false);
    const [file, setFile] = useState(false);
    const handleModalShow = (filename) =>{
        console.log(filename)
        setModalshow(true);
        setFile(filename);
    } 

    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const myday = pathSegments[pathSegments.length - 1];

    const [colDefs, setColDefs] = useState([
        { headerName: "First Name", field: 'firstname' },
        { headerName: "Last Name", field: 'lastname' },
        { headerName: "Job Title", field: 'jobtitle' },  
        { headerName: "Attachment",
            cellRenderer: Pdf,
        }, 
        { headerName: "Experience", field: 'experience' },
        { headerName: "Current Location", field: 'location' },
        { headerName: "Relocation", field: 'relocation' },
        { headerName: "Work Authorization" , field: 'workauthorization'}, 
        { headerName: "Added By" , field: 'firstname'},    
        { headerName: "Submitted Date", field: 'created_at',
            valueFormatter: (params) => {
                return moment(params.value).format('DD-MMM-YYYY');
            }
        },     
    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get(`http://localhost:8000/api/recruiter/rcreports/${userdetails.email}/${myday}`);
            console.log(result.data);
            setRowData(result.data.rcreports);
        };
        fetchData();
    }, []);

    return (
        <Container fluid>
            <Talentmanagementmenu />
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>Reports - {myday}</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} >
                    <div class="dropdown float-end">
                    </div>
                </Col>
                <div className="ag-theme-quartz"  style={{ height: 550, }} >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                    />
                </div>
            </Row>
            <Innerfooter/>
        </Container>

    );
}

export default Rcreports