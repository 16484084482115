import React,{ useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-tagsinput/react-tagsinput.css';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import Innerfooter from '../Innerfooter';
import AuthUser from '../AuthUser';
import axios from 'axios';

function Profile() {
  const {http,getUser,setUser} = AuthUser();
  var userdetails = getUser();
  
  const date = new Date(userdetails.created_at);
  // Format the date as "Dec 29, 2023"
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'short', // "Dec"
    day: 'numeric', // "29"
    year: 'numeric', // "2023"
  });

  const handleSave = ({ name, value }) => {
    console.log(value);
    console.log(name);
    http.post(`/recruiter/editprofile/${userdetails.email}`,{fieldname:name,updatevalue:value}).then((response) =>{
      console.log(response.data)
      setUser(response.data.profile);
      userdetails = getUser();  
    }).catch(function (error){
      console.log(error);
    });
  };

  return (
    <Container fluid>
      <br/><br/>
      <Card style={{ width: '33rem' }} className='m-auto rounded-0'>
        <Card.Body>
          <Card.Text >
            <Row>
              <Col>
                <div class="box-top">
                  <div class="profile">
                    <div class="profile-img">
                      <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                    </div>
                    <div class="name-user">
                        <strong>{userdetails.firstname} {userdetails.lastname}</strong>
                        <span>{userdetails.designation}</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <td className='fw-bold'>First Name</td>
                      <td>
                        <EditText
                            style={{width:375}}
                            name='firstname'
                            defaultValue={userdetails.firstname}
                            editButtonProps={{ style: { marginLeft: '5px', width: 16 } }}
                            showEditButton
                            onSave={handleSave}
                          />
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-bold'>Last Name</td>
                      <td>
                        <EditText
                            style={{width:375}}
                            name='lastname'
                            defaultValue={userdetails.lastname}
                            editButtonProps={{ style: { marginLeft: '5px', width: 16 } }}
                            showEditButton
                            onSave={handleSave}
                          />
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-bold'>Member since</td>
                      <td>
                        <EditText
                            style={{width:375}}
                            name='textbox2'
                            defaultValue={formattedDate}
                            editButtonProps={{ style: { marginLeft: '5px', width: 16 } }}
                            showEditButton
                            readonly
                          />
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-bold'> Email </td>
                      <td>
                        <EditText
                            style={{width:375}}
                            name='email'
                            defaultValue={userdetails.email}
                            editButtonProps={{ style: { marginLeft: '5px', width: 16 } }}
                            showEditButton
                            readonly
                          />
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-bold'>Contact No </td>
                      <td>
                        <EditText
                            style={{width:375}}
                            name='mobile'
                            defaultValue={userdetails.mobile}
                            editButtonProps={{ style: { marginLeft: '5px', width: 16 } }}
                            showEditButton
                            onSave={handleSave}
                          />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col></Col>
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>
    <Innerfooter/>
  </Container>

  );
}

export default Profile