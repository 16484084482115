import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import { CardLink } from 'react-bootstrap';
import Innerfooter from '../Innerfooter';
import Talentmanagementmenu from '../Talentmangementmenu';
import AuthUser from '../AuthUser';
import axios from 'axios';

function Reports() {
  const{getUser,http} = AuthUser();
  let userdetails = getUser();
  const currYear = new Date().getFullYear();
  const Y1 =  currYear -1;
  const Y2 =  currYear -2;

  const handleDownloadPDF = async(id) => {
      http.get(`/recruiter/rcdownloadpdf/${userdetails.email}/${id}`, { responseType: 'blob' })
          .then((response) =>{
              console.log(response);
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'rcreports.pdf'); 
              document.body.appendChild(link);
              link.click();
              link.remove();
          }
      ).catch (function (error) {
          console.error('Error downloading the PDF:', error);
      });
  };

  return (
    <Container fluid>
      <Talentmanagementmenu />
      <Container>
        <Row className='mb-5 mt-5'>
          <Col sm={6} className='m-auto'>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Reports</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Today</td>
                  <td> <CardLink  as={Link} to="/Rcreports/Today">View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF('Today')}> Download	</CardLink></td>
                </tr>
                <tr>
                  <td>Weekly</td>
                  <td> <CardLink as={Link} to="/Rcreports/Weekly">View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF('Weekly')}> Download	</CardLink> </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Monthly</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Jan</td>
                  <td> <CardLink as={Link} to="/Rcreports/January">View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF('January')}> Download	</CardLink> </td>
                </tr>
                <tr>
                  <td>Feb</td>
                  <td> <CardLink as={Link} to="/Rcreports/February">View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF('February')}> Download	</CardLink> </td>
                </tr>
                <tr>
                  <td>Mar</td>
                  <td> <CardLink as={Link} to="/Rcreports/March">View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF('March')}> Download	</CardLink> </td>
                </tr>
                <tr>
                  <td>Apr</td>
                  <td> <CardLink as={Link} to="/Rcreports/April">View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF('April')}> Download	</CardLink> </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Quarterly</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Q1</td>
                  <td> <CardLink as={Link} to="/Rcreports/Q1">View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF('Q1')}> Download	</CardLink> </td>
                </tr>
                <tr>
                  <td>Q2</td>
                  <td> <CardLink as={Link} to="/Rcreports/Q2">View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF('Q2')}> Download	</CardLink> </td>
                </tr>
                <tr>
                  <td>Q3</td>
                  <td> <CardLink as={Link} to="/Rcreports/Q3">View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF('Q3')}> Download	</CardLink> </td>
                </tr>
                <tr>
                  <td>Q4</td>
                  <td> <CardLink as={Link} to="/Rcreports/Q4">View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF('Q4')}> Download	</CardLink> </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>History</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2022</td>
                  <td> <CardLink as={Link} to={`/Rcreports/${Y2}`}>View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF(Y2)}> Download	</CardLink> </td>
                </tr>
                <tr>
                  <td>2023</td>
                  <td> <CardLink as={Link} to={`/Rcreports/${Y1}`}>View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF(Y1)}> Download	</CardLink> </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Current Year 2024</th>
                  <th><CardLink as={Link} to={`/Rcreports/${currYear}`}>View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF(currYear)}> Download	</CardLink></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Year to Date</td>
                  <td> <CardLink as={Link} to={`/Rcreports/${currYear}`}>View </CardLink> | <CardLink href="#" onClick={() => handleDownloadPDF(currYear)}> Download	</CardLink></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <Innerfooter />
    </Container>
  );
}
export default Reports