import React,{ useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Talentmanagementmenu from '../Talentmangementmenu';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Innerfooter from '../Innerfooter';
import AuthUser from '../AuthUser';
import axios from 'axios';
import InputMask from 'react-input-mask';
  
function AddH1BTalent() {
  const{getUser,http} = AuthUser();
  let userdetails = getUser();
  const [countries, setCountries] = useState([]);
  const [workauths, setWorkauths] = useState([]);
  const [count, setCount] = useState(1);
  const [countmonth, setCountmonth] = useState(1);
  const incrementYear = () => {
    setCount(prevCount => prevCount + 1);
  };

  const decrementYear = () => {
    setCount(prevCount => prevCount - 1);
  };
  const incrementValue = () => {
    setCountmonth(prevValue => prevValue + 1);
  };

  const decrementValue = () => {
    setCountmonth(prevValue => prevValue - 1);
  };
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    jobtitle: '',
    experience: '',
    months: '',
    salary: '',
    country: '',
    workauthorization: '',
    resume: '',
    id: userdetails.id,
  });
  
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState('');
  const handleChange = (e) => {
    if (e.target.name == "resume") {
      setFormData({ ...formData, resume: e.target.files[0] });
    } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  function fetchData(){
      http.get(`/recruiter/countries/${userdetails.email}`).then((response) => {
        setCountries(response.data.countries);
      }).catch()
  }

  function fetchData1(){
      http.get(`/recruiter/workauths/${userdetails.email}`).then((response) => {
        setWorkauths(response.data.workauths);
      }).catch()
  }

  useEffect(() => {
      fetchData();
      fetchData1();
  }, []);

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{3}-\d{3}-\d{4}$/;
    const allowedFileTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
      //"text/plain",
    ];
    const maxFileSize = 5 * 1024 * 1024; 
       
    if (!formData.firstname.trim()) newErrors.firstname = 'First Name is required.';
    if (!formData.lastname.trim()) newErrors.lastname = 'Last Name is required.';
    if (!formData.email || !emailRegex.test(formData.email)) newErrors.email = 'Invalid email address.';
    if (!formData.mobile || !mobileRegex.test(formData.mobile)) newErrors.mobile = 'Mobile number must be in the format 999-999-9999.';
    if (!formData.jobtitle.trim()) newErrors.jobtitle = 'Job Title is required.';
    if (!formData.salary.trim()) newErrors.salary = 'Salary or percentage is required.';
    if (!formData.country.trim()) newErrors.country = 'Country is required.';
    if (!formData.workauthorization.trim()) newErrors.workauthorization = 'Work authorization is required.';
    if (!formData.resume) newErrors.resume = 'Resume is required.';
    else if (!allowedFileTypes.includes(formData.resume.type)) newErrors.resume = 'Invalid file type. Allowed types: .doc, .docx, .pdf..';
    else if (formData.resume.size > maxFileSize) newErrors.resume = 'File size exceeds 5 MB.';
        
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    setErrors({}); 

    let firstname=e.target.firstname.value;
    let lastname=e.target.lastname.value;
    let jobtitle=e.target.jobtitle.value;
    let email=e.target.email.value;
    let mobile=e.target.mobile.value;
    let experience=e.target.experience.value;
    let months=e.target.months.value;
    let country=e.target.country.value;
    let salary=e.target.salary.value;
    let linkedinid=e.target.linkedinid.value;
    let workauthorization=e.target.workauthorization.value;
    let resume = e.target.resume.files[0];

    if (validate()) {
      http.post(`/recruiter/addh1btalent/${userdetails.email}`, {firstname:firstname,lastname:lastname,jobtitle:jobtitle,email:email,mobile:mobile,experience:experience,
        months:months,country:country,salary:salary,linkedinid:linkedinid,workauthorization:workauthorization,resume:resume},
        { headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) =>{
          setSuccess(response.data.message);
        }
      ).catch (function (error) {
          if (error.response && error.response.status === 403) {
              setErrors(error.response.data.error);
          }
      });
    }
  };
  


    return (
        <Container fluid>
          <Talentmanagementmenu />
          <Row className='mb-5 mt-5'>
          <Card style={{ width: '40rem' }} className='m-auto rounded-0'>
            <Card.Body>
              <Card.Title>Add H1B Lottery Applicant</Card.Title>
              <Card.Text>
                <div class="mb-3">
                { success !== '' && <div className="alert alert-success">{success}</div> }
                  <Form method="post" onSubmit={handleSubmit} >
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className='fw-bold'>First Name *</Form.Label>
                      <Form.Control type="text" name='firstname' className="rounded-0 postjobinput " placeholder="Enter First Name" value={formData.firstname} onChange={handleChange} isInvalid={!!errors.firstname} />
                      <span style={{display:'block', color: "#bc3f34" }} className="invalid-feedback">{errors.firstname}</span> 
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className='fw-bold'>Last Name *</Form.Label>
                      <Form.Control type="text" name='lastname' className="rounded-0 postjobinput " placeholder="Enter Last Name" value={formData.lastname} onChange={handleChange} isInvalid={!!errors.lastname} />
                      <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className='fw-bold'>Email ID *</Form.Label>
                      <Form.Control type="email" name='email' className="rounded-0 postjobinput " placeholder="Enter Your Email" value={formData.email} onChange={handleChange} isInvalid={!!errors.email} />
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className='fw-bold'>Mobile *</Form.Label>
                      <InputMask mask="999-999-9999" className="rounded-0 postjobinput form-control" value={formData.mobile} onChange={handleChange} name="mobile" isInvalid={!!errors.mobile}>
                        {(inputProps) => <input {...inputProps} type="text" />}
                      </InputMask>
                      <Form.Control.Feedback type="invalid" style={{ display:'block'}}>{errors.mobile}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className='fw-bold'>Job Title *</Form.Label>
                      <Form.Control type="text" name='jobtitle' className="rounded-0 postjobinput " placeholder="Enter Job Title" value={formData.jobtitle} onChange={handleChange} isInvalid={!!errors.jobtitle} />
                      <Form.Control.Feedback type="invalid">{errors.jobtitle}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className='fw-bold'>Experience *</Form.Label>
                      <div class="number  d-flex justify-content-between">
                        <span onClick={decrementYear}  className="minus">-</span>
                        <input type="number" className="form-control" style={{ border:'1px'}} name="experience" value={count} />
                        <span onClick={incrementYear } className="plus" >+</span>
                        <span onClick={decrementValue} className="minus">-</span>
                        <input type="number" className="form-control" style={{ border:'1px'}} name="months" value={countmonth} />
                        <span onClick={incrementValue} className="plus" >+</span>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1 ">
                      <Form.Label className='fw-bold'>Salary or Percentage *</Form.Label>
                      <Form.Select aria-label="Default select example" name='salary' className='rounded-0 postjobinput'  value={formData.salary} onChange={handleChange} isInvalid={!!errors.salary} >
                        <option value="">Select Salary or Percentage</option>
                        <option value="Salary">Salary</option>
                        <option value="Percentage">Percentage</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.salary}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1 ">
                    <Form.Label className='fw-bold'>Current Country *</Form.Label>
                      <Form.Select aria-label="Default select example" name='country' className='rounded-0 postjobinput' onChange={handleChange} isInvalid={!!errors.country} >
                      <option value="" selected="" disabled="">Current Country</option>
                      {countries.map(country => (
                        <option value={country.countryname}>{country.countryname}</option>
                      ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className='fw-bold'>LinkedIn ID / Employmentexchange ID</Form.Label>
                      <Form.Control type="text" name='linkedinid' className="rounded-0 postjobinput " placeholder="LinkedIn ID / Employmentexchange ID" value={formData.linkedinid} onChange={handleChange} isInvalid={!!errors.linkedinid} />
                      <Form.Control.Feedback type="invalid">{errors.linkedinid}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1 ">
                    <Form.Label className='fw-bold'>Work Authorization *</Form.Label>
                      <Form.Select aria-label="Default select example" name='workauthorization' className='rounded-0 postjobinput' value={formData.workauthorization} onChange={handleChange} isInvalid={!!errors.workauthorization} >
                      <option value="" selected="" disabled="">Work Authorization</option>
                      {workauths.map(workauth => (
                        <option value={workauth.workauthorization}>{workauth.workauthorization}</option>
                      ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.workauthorization}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label className='fw-bold'>Upload CV *</Form.Label>
                      <Form.Control type="file" name='resume'  className='rounded-0 postjobinput'  onChange={handleChange} isInvalid={!!errors.resume} />
                      <Form.Control.Feedback type="invalid">{errors.resume}</Form.Control.Feedback>
                    </Form.Group>
                    <div className='text-center'>
                      <button type="submit" class="btn btn-primary rounded-pill" style={{ backgroundColor: '#2F9DCC' }}>Submit</button>
                    </div>
                  </Form>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          </Row>
          <Innerfooter/>
        </Container>
    );
}

export default AddH1BTalent