import React,{useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Signupimg from '../Assets/Signup.png';
import Header from '../Components/Mainheader';
import Col from 'react-bootstrap/Col';
import AuthUser from './AuthUser';

function Createpassword() {
    const{http} = AuthUser();
    let currentYear = new Date().getFullYear() 
    const token = window.location.href.split('/')[4]
    const email = window.location.href.split('/')[5]

    const [formData, setFormData] = useState({
        password: '',
        reconfirmpassword: '',
		    email: email,
        token: token,
    });

    const [errors, setErrors] = useState({});
	  const [success, setSuccess] = useState();
    const handleChange = (e) => {
        setFormData({ ...formData,[e.target.name]: e.target.value,});
    };

    const validate = () => {
        let newErrors = {}; 
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      
        if (!formData.password.trim()) 
            newErrors.password = 'The new password is required.';
        else if (!passwordRegex.test(formData.password)) 
            newErrors.password = 'Minimum 8 characters, at least one letter, one number, and one special character.';
        
        if (!formData.reconfirmpassword.trim()) 
            newErrors.reconfirmpassword = 'The reconfirm password is required.';
        else if (formData.password !== formData.reconfirmpassword) 
            newErrors.reconfirmpassword = 'Password and reconfirm password do not match.';
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }; 
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(validate()){
          http.post(`/recruiter/createpassword`, {password:formData.password,reconfirmpassword:formData.reconfirmpassword,email:email,token: token})
          .then((response) =>{
              setSuccess(response.data.message);
              setFormData({  password: '', reconfirmpassword: ''});
            }
          ).catch (function (error) {
              if (error.response && error.response.status === 403) {
                  setErrors(error.response.data.error);
              }
          });
        }

    };
    

    
  return (
    <Container fluid   style={{backgroundColor:'#F7F7F7'}}>
      <Header/>
      <Row className='min-vh-75'>
        <Col sm={6} className='pt-5 pl-4 text-center'>
          <img src={Signupimg} alt='Profile' className='img-fluid mt-5' width={550} />
        </Col>
        <Col sm={6} className='p-5'>
          <Col sm={6} >
            <Card className='rounded mt-5 text-white p-3' style={{backgroundColor:'#2488B4'}}>
              <Card.Body>
                <h5 className='text-white'>Create Password</h5>
                <div className='alert-success'>{success}</div>
                <Form className='mt-3' method="post" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control type="password" name='password' className='rounded-0 p-3 shadow-sm' placeholder="Password" value={formData.password} onChange={handleChange}/>
                    <Form.Control.Feedback type="invalid" style={{ color: '#ffffff', display: 'block'}}>{errors.password}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control type="password" name='reconfirmpassword' className='rounded-0 p-3 shadow-sm' placeholder="Reconfirm Password" value={formData.reconfirmpassword} onChange={handleChange}/>
                    <Form.Control.Feedback type="invalid" style={{ color: '#ffffff', display: 'block'}}>{errors.reconfirmpassword}</Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-grid gap-2">
                    <Button variant="primary"type="submit" size="lg" style={{ backgroundColor: "#78A644" }} className='rounded-0 fw-bold border-0 shadow'>Submit</Button>
                  </div>
                </Form>            
              </Card.Body>
            </Card>
            <span className='small'>Copyrights © {currentYear} Employment Exchange LLC. All Rights Reserved</span>
          </Col>
        </Col>
      </Row>
    </Container>
  );


}

export default Createpassword