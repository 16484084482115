import React ,{ useState, useEffect, useMemo } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Talentmanagementmenu from '../Talentmangementmenu';
import Col from 'react-bootstrap/Col';
import Innerfooter from '../Innerfooter';
import 'react-tagsinput/react-tagsinput.css';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import RPdf from "../RPdf.jsx";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { CardLink } from 'react-bootstrap'; 
import {
    Dropdown,
    DropdownButton,
    ButtonGroup,
  } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import AuthUser from '../AuthUser';
import axios from 'axios';
import moment from 'moment';
import InputMask from 'react-input-mask';

function Referrals() {
    const{http,getUser} = AuthUser();
    let userdetails = getUser();
    const [smShow, setSmShow] = useState(false);
    const [smShow1, setSmShow1] = useState(false);

    function fetchData(){
        http.get(`/recruiter/rcreferrals/${userdetails.email}`).then((response) => {
            setRowData(response.data.rcreferrals);
        }).catch()
    }
    useEffect(() => {
        fetchData();
    }, []);
    const refreshData = () =>{
        fetchData();
    }

    const handleDelete = (id) => {
        http.get(`/recruiter/deletereferral/${userdetails.email}/${id}`).then((response) => { refreshData(); }).catch()
    };

    const [referral,setReferral]=useState({});
    const [errors1, setErrors1] = useState({});
    const [success1, setSuccess1] = useState('');
    const handleChange1 = (e) => {
        setReferral({ ...referral,[e.target.name]: e.target.value,});
    };
    const handleEdit = (id) => {
        http.get(`/recruiter/referral/${userdetails.email}/${id}`).then(
            (response) =>{
                setReferral(response.data.referral)
                setSmShow1(true);
            }
        ).catch();
    };

    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState('');
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        attachment: '',
        id: userdetails.id,
      });
    const handleChange = (e) => {
        setFormData({...formData,[e.target.name]: e.target.value });
    };
    const validate = () => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const nameRegex = /^[a-zA-Z\s]*$/;
        const mobileRegex = /^\d{3}-\d{3}-\d{4}$/;
        const allowedFileTypes = [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/pdf",
            //"text/plain",
        ];
        const maxFileSize = 5 * 1024 * 1024; 
           
        if (!formData.firstname || !nameRegex.test(formData.firstname)) newErrors.firstname = 'First Name should contains alphabets and space.';
        if (!formData.lastname || !nameRegex.test(formData.lastname)) newErrors.lastname = 'Last Name should contains alphabets and space.';
        if (!formData.email || !emailRegex.test(formData.email)) newErrors.email = 'Invalid email address.';
        if (!formData.phone || !mobileRegex.test(formData.phone)) newErrors.phone = 'Phone number must be in the format 999-999-9999.';
        if (!formData.attachment.trim()) newErrors.attachment = 'Resume is required.';
        else if (!allowedFileTypes.includes(formData.attachment.type)) newErrors.attachment = 'Invalid file type. Allowed types: .doc, .docx, .pdf..';
        else if (formData.attachment.size > maxFileSize) newErrors.attachment = 'File size exceeds 5 MB.';
            
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        console.log(formData);
        e.preventDefault();
        setErrors({});
        
        let firstname=e.target.firstname.value;
        let lastname=e.target.lastname.value;
        let phone=e.target.phone.value;
        let email=e.target.email.value;
        let attachment = e.target.attachment.files[0];

        if (validate()) {
            http.post(`/recruiter/addrcreferral/${userdetails.email}`, {firstname:firstname,lastname:lastname,email:email,
                phone:phone,attachment:attachment}, { headers: { 'Content-Type': 'multipart/form-data' }, })
            .then((response) =>{
                setSuccess(response.data.message);
                setFormData({ firstname: '',lastname: '',email: '',phone: '',attachment: '' });
                refreshData();
                }
            ).catch (function (error) {
                if (error.response && error.response.status === 403) {
                    setErrors(error.response.data.error);
                }
            });
        }    
    };

    const validate1 = () => {
        const newErrors1 = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const mobileRegex = /^\d{3}-\d{3}-\d{4}$/;
        const allowedFileTypes = [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/pdf",
            //"text/plain",
        ];
        const maxFileSize = 5 * 1024 * 1024; 
           
        if (!referral?.firstname.trim()) newErrors1.firstname = 'First Name is required.';
        if (!referral?.lastname.trim()) newErrors1.lastname = 'Last Name is required.';
        //if (!referral.email || !emailRegex.test(referral.email)) newErrors1.email = 'Invalid email address.';
        if (!referral?.phone || !mobileRegex.test(referral?.phone)) newErrors1.phone = 'Phone number must be in the format 999-999-9999.';
        if (!referral?.attachment.trim()) {
            if (!allowedFileTypes.includes(referral?.attachment.type)) newErrors1.attachment = 'Invalid file type. Allowed types: .doc, .docx, .pdf..';
            else if (referral?.attachment.size > maxFileSize) newErrors1.attachment = 'File size exceeds 5 MB.';
        }   
        setErrors1(newErrors1);
        return Object.keys(newErrors1).length === 0;
    };

    const handleSubmit1 = async (e) => {
        e.preventDefault();
        setErrors1({});
        let id = referral.id;
        if (validate1()) {
            http.post(`/recruiter/updatereferral/${userdetails.email}/${id}`, referral , { headers: { 'Content-Type': 'multipart/form-data' },})
            .then((response) =>{
                setSuccess1(response.data.message);
                refreshData();
                }
            ).catch (function (error) {
                if (error.response && error.response.status === 403) {
                    setErrors1(error.response.data.error);
                }
            });
        }

    };

   
    const [rowData, setRowData] = useState([]);
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { headerName: "First Name", field: "firstname",  
            cellRenderer: (params) =>{
                return(
                    <>
                        {params.data.firstname}
                        <DropdownButton  as={ButtonGroup} size="sm" autoClose="outside" variant="muted" style={{ maxHeight: "28px" }}
                            title={ <span><i class="bi bi-three-dots"></i></span> }>
                            <Dropdown.Item onClick={() => handleEdit(params.data.id)} style={{ marginTop:'-15px'}}>Edit</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDelete(params.data.id)}>Delete</Dropdown.Item> 
                        </DropdownButton>
                    </>
                )
            },
            cellStyle: { overflow: "visible", zIndex: "auto" },// Set this line
            resizable: true,
            suppressMenu: true,
            minWidth: 100,
            width: 80
        },
        { headerName: "Last Name", field: 'lastname' },
        { headerName: "Email", field: 'email' },
        { headerName: "Phone Number", field: 'phone' }, 
        { field: "Attachment",
            cellRenderer: RPdf,
          },  
        { headerName: "Date", field: 'created_at',
            valueFormatter: (params) => {
                return moment(params.value).format('DD-MMM-YYYY');
            }
         },     

    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,
        }
    });

 
    return (
        <Container fluid>
            <Talentmanagementmenu />
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>My Referrals</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                    
                </Col>
                <Col sm={4} className='d-flex justify-content-end'>
                    <CardLink href="#" onClick={() => setSmShow(true)}><button type="submit" class="btn btn-primary " style={{ backgroundColor: '#2F9DCC' }}>Add New Referrals</button></CardLink>   
                </Col>
                <div className="ag-theme-quartz mt-2"  style={{ height: 550 }} >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                        rowSelection={"multiple"}
                    />
                </div>
            </Row>
            <Innerfooter/>

            <Modal size="md" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="example-modal-sizes-title-sm" >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <span className='fw-bold'>Add New Referrals </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                { success !== '' && <div className="alert alert-success">{success}</div> }
                    <Form method='post'  onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'> First Name *</Form.Label>
                            <Form.Control type="text" name='firstname' className="rounded-0 postjobinput " placeholder="Enter First Name" value={formData.firstname}  onChange={handleChange} isInvalid={!!errors.firstname}/>
                            <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'>Last Name *</Form.Label>
                            <Form.Control type="text" name='lastname' className="rounded-0 postjobinput " placeholder="Enter Last Name" value={formData.lastname} onChange={handleChange} isInvalid={!!errors.lastname}/>
                            <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'>Email *</Form.Label>
                            <Form.Control type="email" name='email' className="rounded-0 postjobinput " placeholder="name@email.com" value={formData.email} onChange={handleChange} isInvalid={!!errors.email}/>
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'>Phone Number *</Form.Label>
                            <InputMask mask="999-999-9999" name='phone' placeholder="Enter Phone number" className="rounded-0 form-control" value={formData.phone} onChange={handleChange} isInvalid={!!errors.phone} >
                                {(inputProps) => <input {...inputProps} type="text" />}
                            </InputMask>
                            <Form.Control.Feedback type="invalid" style={{ display:'block'}} >{errors.phone}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'> Attachment * </Form.Label>
                            <Form.Control type="file" name='attachment' className="rounded-0 postjobinput " value={formData.attachment}  onChange={handleChange} isInvalid={!!errors.attachment}/>
                            <Form.Control.Feedback type="invalid">{errors.attachment}</Form.Control.Feedback>
                        </Form.Group>                                
                        <hr></hr>
                        <div className='text-center'>
                            <button type="submit" class="btn btn-primary rounded-pill" style={{ backgroundColor: '#2F9DCC' }}>Submit</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal size="md" show={smShow1} onHide={() => setSmShow1(false)} aria-labelledby="example-modal-sizes-title-sm" >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <span className='fw-bold'>Edit Referral </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                { success1 !== '' && <div className="alert alert-success">{success1}</div> }
                    <Form method='post'  onSubmit={handleSubmit1}>
                        <input type='hidden' value={referral?.id} ></input>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'> First Name *</Form.Label>
                            <Form.Control type="text" name='firstname' className="rounded-0 postjobinput " placeholder="Enter First Name" value={referral?.firstname}  onChange={handleChange1} isInvalid={!!errors1.firstname}/>
                            <Form.Control.Feedback type="invalid">{errors1.firstname}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'>Last Name *</Form.Label>
                            <Form.Control type="text" name='lastname' className="rounded-0 postjobinput " placeholder="Enter Last Name" value={referral?.lastname} onChange={handleChange1} isInvalid={!!errors1.lastname}/>
                            <Form.Control.Feedback type="invalid">{errors1.lastname}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'>Email *</Form.Label>
                            <Form.Control type="email" name='email' className="rounded-0 postjobinput " placeholder="name@email.com" value={referral?.email}  disabled/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'>Phone Number *</Form.Label>
                            <InputMask mask="999-999-9999" name='phone' placeholder="Enter Phone number" className="rounded-0 form-control" value={referral?.phone} onChange={handleChange} isInvalid={!!errors.phone} >
                                {(inputProps) => <input {...inputProps} type="text" />}
                            </InputMask>
                            <Form.Control.Feedback type="invalid" style={{ display:'block'}} >{errors.phone}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'> Attachment * </Form.Label>
                            <Form.Control type="file" name='attachment' className="rounded-0 postjobinput" onChange={handleChange} isInvalid={!!errors.attachment}/>
                            <Form.Control.Feedback type="invalid">{errors.attachment}</Form.Control.Feedback>
                        </Form.Group>                                
                        <hr></hr>
                        <div className='text-center'>
                            <button type="submit" class="btn btn-primary rounded-pill" style={{ backgroundColor: '#2F9DCC' }}>Submit</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>

    );
}

export default Referrals