import React,{ useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Innerfooter from '../Innerfooter';
import AuthUser from '../AuthUser';

function Changepassword() {
  const{getUser,http} = AuthUser();
  let userdetails = getUser();

  const [formData, setFormData] = useState({ currentpassword: '', newpassword: '', reconfirmpassword: ''});
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState('');
  const handleChange = (e) => {
      console.log(e.target.value);
      setFormData({...formData,[e.target.name]: e.target.value });
  };

  const validate = () => {
      let newErrors = {}; 
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        
      if (!formData.currentpassword.trim()) 
        newErrors.currentpassword = 'The current password is required.';
    
      if (!formData.newpassword.trim()) 
          newErrors.newpassword = 'The new password is required.';
      else if (!passwordRegex.test(formData.newpassword)) 
          newErrors.newpassword = 'Minimum 8 characters, at least one letter, one number, and one special character.';
      
      if (!formData.reconfirmpassword.trim()) 
          newErrors.reconfirmpassword = 'The reconfirm password is required.';
      else if (formData.newpassword !== formData.reconfirmpassword) 
          newErrors.reconfirmpassword = 'New password and reconfirm password do not match.';
      
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
  };   
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = {};
    if(validate()){
      http.post(`/recruiter/changepassword/${userdetails.email}`, formData)
      .then((response) =>{
          setSuccess(response.data.message);
        }
      ).catch (function (error) {
          if (error.response && error.response.status === 403) {
              //setErrors(error.response.data.error);
              newErrors.currentpassword = error.response.data.error;
              setErrors(newErrors);
          }
      });
    }
  };

  return (
    <Container fluid>
      <Container className='mt-2'>
        <Row className='min-vh-75'>
          <Card className='rounded-0 ' style={{ width: '25rem', margin:'auto'}}>
            <Card.Body>
              <p className='fw-bold'>Change Password</p>
              <hr></hr>
              <Row className='p-2'>
                { success !== '' && <div className="alert alert-success">{success}</div> }
                <Form method="post" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Current Password *</Form.Label>
                    <Form.Control type="password" name="currentpassword" placeholder="Enter Current Password" value={formData.currentpassword} onChange={handleChange} isInvalid={!!errors.currentpassword} className='rounded-0' />
                    <Form.Control.Feedback type="invalid" style={{ display:'block'}}>{errors.currentpassword}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>New Password *</Form.Label>
                    <Form.Control type="password" name="newpassword" placeholder="Enter New Password" value={formData.newpassword} onChange={handleChange} isInvalid={!!errors.newpassword} className='rounded-0' />
                    <Form.Control.Feedback type="invalid" style={{ display:'block'}}>{errors.newpassword}</Form.Control.Feedback> 
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Re-confirm Password *</Form.Label>
                    <Form.Control type="password" name="reconfirmpassword" placeholder="Enter Re-confirm Password" value={formData.reconfirmpassword} onChange={handleChange} isInvalid={!!errors.reconfirmpassword} className='rounded-0' />
                    <Form.Control.Feedback type="invalid" style={{ display:'block'}}>{errors.reconfirmpassword}</Form.Control.Feedback>                  
                  </Form.Group>
                  <Col className='text-center'>
                    <Button variant="primary" type="submit" className='rounded-0 ml-2'>Update</Button>
                  </Col>
                </Form>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    <Innerfooter/>
    </Container>
  );
}
  
export default Changepassword