import React ,{ useState, useEffect, useMemo } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Talentmanagementmenu from '../Talentmangementmenu';
import Col from 'react-bootstrap/Col';
import Innerfooter from '../Innerfooter';
import 'react-tagsinput/react-tagsinput.css';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Pdf from "../Pdf.jsx";
import Flagdisplay from "../Flagdisplay.jsx";
import Flag from "../Flag.jsx";
import Comments from "../Comments.jsx";
import { Dropdown } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import pdf from '../../Assets/pdf_ico.png'
import Doc from '../../Assets/word_icon.png';
import Viewdocument from '../Viewdocument.jsx';
import AuthUser from '../AuthUser';
import axios from 'axios';
import moment from 'moment';

function TalentCenter() {
    const{http,getUser} = AuthUser();
    let userdetails = getUser();
    const [location, setLocation] = useState('');
    const [experience, setExperience] = useState('');
    const [months, setMonths] = useState('');
    const [jobtitle, setJobtitle] = useState('');
    const [relocation, setRelocation] = useState('');

    const [rowData, setRowData] = useState([]);
    const [states, setStates] = useState([]);
    const folderName = 'rctalents';
    const [modalshow, setModalshow] = useState(false);
    const [file, setFile] = useState(false);
    const handleModalShow = (filename) =>{
        console.log(filename)
        setModalshow(true);
        setFile(filename);
    } 
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        http.get(`/recruiter/searchtalentcenter/${userdetails.email}`, {
            params: {
                location , experience, months, jobtitle,relocation
            },
        })
        .then((response) =>{
            setRowData(response.data.talents);
        }
        ).catch ();
        
    };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await http.get(`/recruiter/states/${userdetails.email}`);
            setStates(response.data.states);
          } catch (error) {
            console.error("Error fetching states:", error);
          }
        };
    
        const fetchData1 = async () => {
          try {
            const response = await http.get(`/recruiter/talentcenter/${userdetails.email}`);
            setRowData(response.data.talents);
          } catch (error) {
            console.error("Error fetching talents:", error);
          }
        };
    
        fetchData();
        fetchData1();
    }, []);


    const [colDefs, setColDefs] = useState([
        { headerName: "First Name", field: 'firstname'},
        { headerName: "Last Name", field: 'lastname' },
        { headerName: "Job Title", field: 'jobtitle' },  
        { headerName: "Attachment",
            cellRenderer: Pdf,
        }, 
        { headerName: "Experience(in Yrs)", field: 'experience' },
        { headerName: "Current Location", field: 'location' },
        { headerName: "Relocation", field: 'relocation' },
        { headerName: "Work Authorization" , field: 'workauthorization'},    
        { headerName: "Comments",
            cellRenderer: Comments,
        },  
        { headerName: "SubmittedDate", field: 'created_at',
            valueFormatter: (params) => {
                return moment(params.value).format('DD-MMM-YYYY');
            }
        },     
    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,
        }
    });

    
    return (
        <Container fluid>
            <Talentmanagementmenu />
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>Talent Center </h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} >
                    <div class="dropdown float-end">
                        <Dropdown>
                            <Dropdown.Toggle variant=""><i class="bi bi-funnel-fill"></i> </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Container>
                                <Form onSubmit={handleSubmit} >
                                <Row style={{ minWidth: "30vw" }}>
                                    <Col>
                                        <Form.Label className='mt-2 fw-bold'>Location</Form.Label>
                                        <Form.Select name='location' aria-label="Default select example" value={location} onChange={(e) => setLocation(e.target.value)}>
                                            <option>Select Location</option>
                                            {states.map(state => (
                                                <option value={state.statename}>{state.statename}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className='mt-2 fw-bold'>Experience</Form.Label>
                                        <Form.Select name='experience' aria-label="Default select example" value={experience} onChange={(e) => setExperience(e.target.value)}>
                                            <option>Select Years</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="10">10 Plus</option>
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        <Form.Label className='mt-2 fw-bold'>Experience</Form.Label>
                                        <Form.Select name='months' aria-label="Default select example" value={months} onChange={(e) => setMonths(e.target.value)}>
                                            <option>Select Months</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Form.Label className='mt-2 fw-bold'>Reloaction</Form.Label>
                                <Form.Select name='relocation' aria-label="Default select example" value={relocation} onChange={(e) => setRelocation(e.target.value)}>
                                    <option>Select Reloaction</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </Form.Select>
                                <Form.Label className='mt-2 fw-bold'>Job Title</Form.Label>
                                <Form.Control name='jobtitle' type="text"  placeholder="Job Title" value={jobtitle} onChange={(e) => setJobtitle(e.target.value)} />
                                <Row className='mt-4'>
                                    <Col lg="6" className='pt-2 small'>
                                        <Card.Link href="#">Clear Search</Card.Link>
                                    </Col>
                                    <Col lg="3" className='pt-2 small'>
                                        <Card.Link href="#">Save Search</Card.Link>
                                    </Col>
                                    <Col lg="3">
                                        <button type="submit" class="btn btn-primary " style={{ backgroundColor: '#2F9DCC' }}>Search</button>
                                    </Col>
                                </Row>
                                </Form>
                                </Container>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>

                <div className="ag-theme-quartz"  style={{ height: 550 }} >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                    />
                </div>
            </Row>
            <Innerfooter/>
        </Container>
    );
}

export default TalentCenter